import React from 'react';
import ScheduleDisplay from '../components/ScheduleDisplay';
import PriceScreen from '../components/PriceScreen';
import PriceScreenCenter from '../components/PriceScreenCenter';
import Edifice511Data from '../content/edifice-511.json';
import AutoRefresh from '../components/AutoRefreshProps';

function Edifice511() {
  const dayContent = (
    <PriceScreen
      title={Edifice511Data.day.title}
      lines={Edifice511Data.day.lines}
    />
  );

  const nightContent = (
    <PriceScreenCenter
      title={Edifice511Data.night.title}
      lines={Edifice511Data.night.lines}
    />
  );
  /* function dayContent() {
    return (
      <PriceScreen
        title={Edifice511Data.day.title}
        lines={Edifice511Data.day.lines}
      />
    );
  }
  function ads1() {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <img
          src={ads2Img}
          alt="Pub test"
          className="h-full w-full object-cover"
        />
      </div>
    );
  }

  function dayContent2() {
    return (
      <PriceScreen
        title={Edifice511Data.day.title}
        lines={Edifice511Data.day.lines}
      />
    );
  }
  function ads2() {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <img
          src={ads1Img}
          alt="Pub test"
          className="h-full w-full object-cover"
        />
      </div>
    );
  }

  const screens = [dayContent, ads1, dayContent2, ads2]; */

  return (
    <div>
      <AutoRefresh intervalms={900000} /> {/* 15 min */}
      <ScheduleDisplay
        dayContent={dayContent}
        nightContent={nightContent}
        dayStartTime="03:00:00"
        nightStartTime="16:00:00" // 16:00:00
      />
      {/* <RotatingComponents screens={screens} interval={3000} /> */}
    </div>
  );
}

export default Edifice511;
