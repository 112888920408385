import React from 'react';
import PriceLine from './PriceLine';
import Header from './Header';
import AnimatedCircles from './AnimatedCircles';

interface Line {
  price: string;
  desc?: string;
}

interface PriceScreenProps {
  title: string;
  lines: Line[];
}

function PriceScreen({ title, lines = [] }: PriceScreenProps) {
  return (
    <div
      className="flex h-screen w-full flex-col items-center justify-between border-[1vh] border-secondary p-[3vw] font-custom text-white"
      style={{ backgroundColor: 'white', color: '#121B4D' }}
    >
      <Header
        title={title}
        logo={
          'https://2csmpgl1jb.ufs.sh/f/h80Ega1oO5qNsGqryG0eofsyb47ZhMrC1dOu36lQtHRKcxvq'
        }
      />
      <div
        className="mt-4 w-full p-6 pt-0 s1000:pt-6"
        style={{ backgroundColor: 'white' }}
      >
        {Array.isArray(lines) &&
          lines.length > 0 &&
          lines.map((lineItem) =>
            lineItem && lineItem.price ? (
              <PriceLine price={lineItem.price} desc={lineItem.desc} />
            ) : null,
          )}
      </div>
      <div className="flex">
        <p
          className="mb-4 px-10 text-left text-[4vw] text-white s1000:text-[2vw]"
          style={{ color: 'white' }}
        >
          Merci pour votre visite !!
        </p>
        <div className="absolute bottom-8 right-8">
          <AnimatedCircles />
        </div>
      </div>
    </div>
  );
}

export default PriceScreen;
