import React from 'react';

function AnimatedCircles() {
  return (
    <div className="relative mb-[2vh] mr-[2vh] flex h-[15vh] w-[15vh] items-center justify-center">
      <img
        src={"https://swqvc4jret.ufs.sh/f/aGnUnqmi8ZcvtTAhVIROUDAukjpxbQ4XdfNE0SyP2MsGvoL3"}
        alt="Circle"
        className="absolute h-full w-full animate-spin-slow"
      />
      <img
        src={"https://swqvc4jret.ufs.sh/f/aGnUnqmi8ZcvXvzUJoyVUpbRaEqekQOl4mKjrfuBTCiWVcyJ"}
        alt="Circle"
        className="absolute h-3/4 w-3/4 animate-spin-reverse"
      />
    </div>
  );
}

export default AnimatedCircles;
