import React from 'react';
import H1Title from './H1Title';

interface HeaderProps {
  title: string;
  logo: string;
}

function Header({ logo, title }: HeaderProps) {
  return (
    <div className="flex w-full flex-col items-center px-10 pt-4 s1000:flex-row-reverse s1000:justify-between">
      <img
        src={logo}
        alt="Parkeo Logo"
        className="mb-4 max-w-[40vw] object-contain s1000:mb-0 s1000:h-[12vh]"
      />
      <H1Title title={title} />
    </div>
  );
}

export default Header;
