import React from 'react';
import ScheduleDisplay from '../components/ScheduleDisplay';
import PriceScreen from '../components/PriceScreen';
import PriceScreenCenter from '../components/PriceScreenCenter';
import jardinData from '../content/jardin-st-roch.json';
import AutoRefresh from '../components/AutoRefreshProps';

function JardinStRoch() {
  const dayContent = (
    <PriceScreen
      title={jardinData.day.title}
      lines={[
        jardinData.day.lines[0],
        jardinData.day.lines[1],
        jardinData.day.lines[2],
      ]}
    />
  );
  const nightContent = (
    <PriceScreenCenter
      title={jardinData.night.title}
      lines={jardinData.night.lines}
    />
  );

  return (
    <div>
      <AutoRefresh intervalms={900000} /> {/* 15 min */}
      <ScheduleDisplay
        dayContent={dayContent}
        nightContent={nightContent}
        dayStartTime="08:00:00"
        nightStartTime="16:00:00" // 16:00:00
      />
    </div>
  );
}

export default JardinStRoch;
