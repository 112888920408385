import React from 'react';
/* import { Link } from 'react-router-dom'; */
import LogoParkeo from '../img/logo-stationnement-parkeo.png';

function Accueil() {
  return (
    <div className="flex h-screen flex-col items-center justify-center space-y-10 border-[1vh] border-secondary">
      <img src={"https://swqvc4jret.ufs.sh/f/aGnUnqmi8Zcvp2IIriwgtP8o6YbXV7uO35re2HMjxEdlDfTQ"} className="max-w-[500px]" alt="logo" />
    </div>
  );
}

export default Accueil;
