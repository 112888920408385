import React from 'react';
import Header from './Header';
import AnimatedCircles from './AnimatedCircles';

interface PriceScreenCenterProps {
  title: string;
  lines: { price: string; desc?: string }[];
}

function PriceScreenCenter({ title, lines }: PriceScreenCenterProps) {
  return (
    <div
      className="flex h-screen w-full flex-col items-center justify-between border-[1vh] border-secondary p-[3vw] font-custom"
      style={{ backgroundColor: '#121B4D', color: 'white' }}
    >
      <Header title={title} logo={"https://swqvc4jret.ufs.sh/f/aGnUnqmi8Zcvpa0fujKwgtP8o6YbXV7uO35re2HMjxEdlDfT"} />
      <div className="mt-8 w-full text-center">
        {lines.map((lineItem, index) => (
          <div className="mb-[2vh]">
            <p
              className={`font-semibold text-white ${index === 0 ? 'text-[8vw]' : 'text-[5vw]'}`}
              style={{ color: 'white' }}
            >
              {lineItem.price}
            </p>
            {lineItem.desc && (
              <p
                className={`${index === 0 ? 'mt-[-2vw]' : 'mt-[-1vw]'} text-[3.4vw] text-secondary`}
                style={{ color: '#8FBA02' }}
              >
                {lineItem.desc}
              </p>
            )}
          </div>
        ))}
      </div>
      <p
        className="mb-4 self-start text-left text-[4vw] text-white"
        style={{ color: 'white' }}
      >
        Merci pour votre visite !!
      </p>
      <div className="absolute bottom-8 right-8">
        <AnimatedCircles />
      </div>
    </div>
  );
}

export default PriceScreenCenter;
